import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigDto, ConfigService } from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { OfficeHelperService } from '@gentext/office';
import { OpenAIApiService } from '@gentext/openai';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, concatMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'gentext-generate-summary',
  templateUrl: './generate-summary.component.html',
  styleUrls: ['./generate-summary.component.css'],
})
export class GenerateSummaryComponent implements OnInit, OnDestroy {
  languageSubscription?: Subscription;
  isLoading = false;
  isErrorNoText = false;
  hasGenerated = false;
  error = '';
  ignoreDocSelChange = false;
  config: ConfigDto = this.configService.getDefaultConfig();

  generatedRange: Word.Range | undefined;
  isRtlLanguage$ = this.configService.isRtlLanguage$;

  get description(): string[] {
    return translate('generateSummary.description');
  }

  constructor(
    private openAIApiService: OpenAIApiService,
    private cdr: ChangeDetectorRef,
    private officeHelper: OfficeHelperService,
    private configService: ConfigService,
    private logging: LoggingService,
  ) {}

  ngOnInit() {
    this.configService.config$.pipe(untilDestroyed(this)).subscribe((c) => {
      this.config = c;
    });
    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        if (this.isLoading) {
          this.logging.trace({
            message: 'Already loading, ignoring doc sel change',
            severityLevel: SeverityLevel.Information,
          });
          return;
        }
        if (this.ignoreDocSelChange) {
          this.ignoreDocSelChange = false;
          return;
        }
        this.hasGenerated = false;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    );
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  private async clearGeneratedRange() {
    if (this.generatedRange) {
      this.generatedRange.delete();
      await this.generatedRange.context.sync();
      this.generatedRange.context.trackedObjects.remove(this.generatedRange);
      this.generatedRange = undefined;
    }
  }
  private async clearParagraphs(paragraphs: Word.Paragraph[]) {
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i];
      paragraph.delete();
      await paragraph.context.sync();
      paragraph.context.trackedObjects.remove(paragraph);
    }
  }
  async run() {
    this.isErrorNoText = false;
    this.error = '';
    return Word.run(async (context) => {
      let text = '';

      const selectedRange = context.document.getSelection();
      context.load(selectedRange, 'text');
      await context.sync();
      text = selectedRange.text;

      if (!text || text === '') {
        this.isErrorNoText = true;
        return;
      }
      const generatedParagraphs: Word.Paragraph[] = [];
      this.isLoading = true;
      this.cdr.detectChanges();
      const prompt = `${text}`;
      try {
        const doc = context.document;
        const originalRange = doc.getSelection();
        let paragraph = originalRange.insertParagraph('', 'After');
        paragraph.styleBuiltIn = Word.BuiltInStyleName.normal;
        context.trackedObjects.add(paragraph);
        context.trackedObjects.add(originalRange);
        await context.sync();
        generatedParagraphs.push(paragraph);
        let allText = '';
        this.openAIApiService
          .generateSummary(prompt)
          .pipe(
            concatMap(async (data) => {
              allText += data;
              data = data.replace('**', '').replace(/#/g, '');
              if (data.endsWith('\n')) {
                const text = data.replace('\n', '');
                paragraph.insertText(text, 'End');
                paragraph = paragraph.insertParagraph('', 'After');
                paragraph.styleBuiltIn = Word.BuiltInStyleName.normal;
                context.trackedObjects.add(paragraph);
                generatedParagraphs.push(paragraph);
              } else {
                paragraph.insertText(data, 'End');
              }
              await context.sync();
            }),
          )
          .subscribe({
            error: (err) => {
              this.logging.trace({
                message: 'Summary component subscription Error',
                properties: { err },
                severityLevel: SeverityLevel.Error,
              });
              this.error = this.officeHelper.getErrorMessage(err);
              this.isLoading = false;
              this.cdr.detectChanges();
            },
            complete: async () => {
              this.logging.trace({
                message: 'Summary component subscription done',
                severityLevel: SeverityLevel.Information,
              });
              await this.clearParagraphs(generatedParagraphs);
              const paragraph = originalRange.insertParagraph('', 'After');
              this.generatedRange = await this.officeHelper.insertMarkdown(
                allText,
                paragraph,
              );
              context.trackedObjects.add(this.generatedRange);
              originalRange.select();

              this.hasGenerated = true;
              this.isLoading = false;
              this.cdr.detectChanges();
              this.ignoreDocSelChange = true;
              await context.sync();
            },
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        this.logging.trace({
          message: 'Summary component generation exception',
          properties: { e },
          severityLevel: SeverityLevel.Error,
        });
        this.error = this.officeHelper.getErrorMessage(e);
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    });
  }
}
