<div
  class="relative flex max-h-screen min-h-screen flex-col bg-white p-1"
  id="app-main-card"
  *transloco="let t"
>
  <div
    class="relative my-2 flex w-full flex-row items-center justify-between bg-white px-4"
  >
    <div class="flex flex-row flex-nowrap items-center gap-2">
      <fluent-select
        title="Select a language"
        class="w-[100px] min-w-[100px]"
        [(ngModel)]="selectedLanguageOption"
        (ngModelChange)="selectedLanguageChange()"
        name="selectedOption"
        ngDefaultControl
      >
        <fluent-option disabled="disabled" value="">A / 文</fluent-option>
        <fluent-option
          *ngFor="let option of languageOptions; let i = index"
          [value]="option.code"
          >{{ option.display }}</fluent-option
        >
      </fluent-select>
    </div>

    <img src="assets/icon-64.png" alt="Company Logo" class="h-6" />

    <!-- menu-class-name is a class used to detect a click outside the menu-->
    <fluent-button
      *ngIf="isAuthenticated"
      class="menu-class-name text-l text-black"
      appearance="lightweight"
      (click)="toggleMenu()"
      data-testid="dropdown-menu-button"
    >
      ☰ {{ t('Menu') }}
    </fluent-button>

    <div
      *ngIf="menuVisible"
      class="absolute right-3 top-[40px] z-50 flex min-w-[120px] flex-col bg-white p-2 shadow-md"
    >
      <div *ngIf="license$ | async as license">
        <fluent-button
          *ngIf="!isManagePlanVisible"
          (click)="managePlan()"
          appearance="filled"
          class="mb-2 w-full text-xs"
        >
          {{ license.planId === 'free' ? 'Upgrade' : 'Manage Plan' }}
        </fluent-button>
        <fluent-button
          *ngIf="isManagePlanVisible"
          appearance="filled"
          class="mb-2 w-full text-xs"
          (click)="goBack()"
        >
          {{ t('Home') }}
        </fluent-button>
      </div>
      <fluent-button
        (click)="goToOldUI()"
        appearance="filled"
        class="mb-2 text-xs"
        data-testid="menu-dropdown-mode"
      >
        {{ t('Dropdown mode') }}
      </fluent-button>
      <fluent-button
        (click)="support()"
        appearance="filled"
        class="mb-2 text-xs"
      >
        {{ t('Help') }}
      </fluent-button>
      <fluent-button
        class="mb-2 text-xs"
        (click)="signOut()"
        appearance="filled"
      >
        {{ t('Sign out') }}
      </fluent-button>
      <div class="text-xs" *ngIf="license$ | async as license">
        {{ t('License') }}:
        {{ license.planId.charAt(0).toUpperCase() + license.planId.slice(1) }}

        <br />{{ t('Words Left') }}:
        <span *ngIf="license.isUnlimited; else tmplWordsLeft">&infin;</span>
        <ng-template #tmplWordsLeft>
          {{ license.isUnlimited ? '&infin; ' : license.wordsLeft }}
        </ng-template>
      </div>
    </div>
  </div>

  <div class="ml-3 flex flex-col">
    <span *ngIf="isAuthenticated && !isManagePlanVisible">
      {{ greeting$ | async }}
    </span>
  </div>
  <div class="px-4">
    <gentext-business-plan-offer></gentext-business-plan-offer>
  </div>

  <div class="grow overflow-y-auto" id="chat-scroll-container">
    <router-outlet></router-outlet>
  </div>
</div>
