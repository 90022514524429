import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigDto, ConfigService } from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentService } from '../document.service';

@UntilDestroy()
@Component({
  selector: 'gentext-generate-research-qa',
  templateUrl: './generate-research-QA.component.html',
  styleUrls: ['./generate-research-QA.component.css'],
})
export class GenerateResearchQAComponent implements OnInit {
  isLoading = false;

  hasGenerated = false;
  error = '';
  config: ConfigDto = this.configService.getDefaultConfig();

  isRtlLanguage$ = this.configService.isRtlLanguage$;

  ignoreDocSelChange = false;

  ngOnInit() {
    this.configService.config$.pipe(untilDestroyed(this)).subscribe((c) => {
      this.config = c;
    });
    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        if (this.isLoading) {
          this.logging.trace({
            message: 'Already loading ignoring doc sel change',
            severityLevel: SeverityLevel.Information,
          });
          return;
        }
        if (this.ignoreDocSelChange) {
          this.ignoreDocSelChange = false;
          return;
        }
        this.hasGenerated = false;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    );
  }

  get description(): string[] {
    return translate('generateResearchQA.description');
  }

  constructor(
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private logging: LoggingService,
    private documentService: DocumentService,
  ) {}

  async run() {
    this.isLoading = true;
    try {
      await this.documentService.generateResearchAnswer();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.error = e;
    } finally {
      this.isLoading = false;
      this.hasGenerated = true;
      this.ignoreDocSelChange = true;
    }
  }
}
