<ng-container *transloco="let t">
  <fluent-listbox
    class="w-full pb-0"
    *ngIf="showOptions; else tmplCurrentOption"
  >
    <!-- 38px left padding to align text with rest of options. 
      each option has: 
      - 20px icon
      - 8px gap to the right
      - 10px padding-left
      => 38px total
    -->
    <fluent-option
      disabled="disabled"
      class="mx-0 pl-[38px]"
      data-testid="dropdown-button-closed"
      >{{ t('optionSelect') }}
    </fluent-option>
    <hr />
    <ng-container
      *ngFor="
        let option of menuOptions$ | async;
        let i = index;
        let last = last
      "
    >
      <fluent-option
        class="mx-0 rounded-none"
        (click)="selectedOptionPathChange(option.path)"
        [value]="option.path"
        data-testid="dropdown-button"
        ><div class="flex flex-row gap-2">
          <img [src]="getIconSrc(option)" width="20px" />
          <span>{{ t(option.titleKey) }}</span>
        </div>
      </fluent-option>
      <hr *ngIf="!last" />
    </ng-container>
  </fluent-listbox>

  <ng-template #tmplCurrentOption>
    <fluent-button (click)="selectedOptionPathChange('')" class="w-full">
      <div class="flex w-full flex-row justify-between gap-10">
        <div>{{ getOptionTitle$(selectedOptionPath) | async }}</div>
        <div [innerHtml]="chevronDown | sanitizeHtml"></div>
      </div>
    </fluent-button>
  </ng-template>
</ng-container>
