import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BASE_PATH,
  DocumentService,
  DocumentSummaryRequest,
} from '@gentext/api-client';
import { AuthService } from '@gentext/auth-office';
import {
  CompletionSettingsDto,
  ConfigDto,
  ConfigService,
} from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as signalR from '@microsoft/signalr';
import {
  BehaviorSubject,
  Observable,
  Subject,
  firstValueFrom,
  map,
  reduce,
} from 'rxjs';
import {
  CompletionRequest,
  CreateChatCompletionRequestDto,
  CreateCompletionRequestDto,
} from './dto';
@Injectable({ providedIn: 'root' })
export class OpenAIApiService {
  private hubUrl = `${this.baseUrl}/hubs/openai`;
  private _documentSummary$ = new BehaviorSubject<string>('');
  private _initialSummaryGenerated$ = new BehaviorSubject(false);
  private _documentSummary = '';
  private _documentSummaryLoading$ = new BehaviorSubject(false);
  private _streamingResponseDone$ = new Subject<void>();
  public streamingResponseDone$ = this._streamingResponseDone$.asObservable();
  public documentSummaryLoading$ = this._documentSummaryLoading$.asObservable();

  private config: ConfigDto = this.configService.getDefaultConfig();

  public documentSummary$ = this._documentSummary$.asObservable();

  public initialSummaryGenerated$ =
    this._initialSummaryGenerated$.asObservable();

  rephraseText(text: string) {
    const config = this.config.rephraseText;
    const prompt = this.generatePrompt(config, text);
    const body: CompletionRequest = {
      model: config.model,
      prompt,
      temperature: config.temperature,
      maxTokens: config.max_tokens,
    };

    return this.streamCreateCompletionResponse(body);
  }

  extractKeywords(text: string): Promise<string[]> {
    const config = this.config.extractKeywords;
    const prompt = this.generatePrompt(config, text);
    const body: CompletionRequest = {
      model: config.model,
      prompt,
      temperature: config.temperature,
      maxTokens: config.max_tokens,
    };
    return firstValueFrom(
      // not including document summary for this request
      this.streamCreateCompletionResponse(body, false).pipe(
        reduce((acc, value) => acc.concat(value), ''),
        map((string) =>
          string
            .split(',')
            .map((keyword) => keyword.replace(/\r?\n|\r/g, '').trim())
            .filter((value, index, array) => array.indexOf(value) === index),
        ),
      ),
    );
  }

  generateAnswer(query: string) {
    const config = this.config.generateResearchQA;
    const prompt = this.generatePrompt(config, query);

    const request: CompletionRequest = {
      model: config.model,
      prompt,
      temperature: config.temperature,
      maxTokens: config.max_tokens,
    };

    return this.streamCreateCompletionResponse(request);
  }

  generateSummary(text: string) {
    const config = this.config.generateSummary;
    const prompt = this.generatePrompt(config, text);
    const body: CompletionRequest = {
      model: config.model,
      prompt,
      temperature: config.temperature,
      maxTokens: config.max_tokens,
    };

    return this.streamCreateCompletionResponse(body, true, false);
  }

  summariseText(text: string) {
    if (this._documentSummaryLoading$.value) {
      this.logging.trace({
        message: 'Already summarising text, ignoring.',
        severityLevel: SeverityLevel.Verbose,
      });
      return;
    }
    this.logging.trace({
      message: 'Summarising text...',
    });
    this._documentSummaryLoading$.next(true);
    const request: DocumentSummaryRequest = { text };

    this.documentService.documentSummarisePost(request).subscribe({
      next: (res) => {
        this.logging.trace({
          message: 'Summarised text successfully.',
          properties: { res },
        });
        this._initialSummaryGenerated$.next(true);
        this._documentSummaryLoading$.next(false);
        this._documentSummary$.next(res.summary);
      },
      error: (err) => {
        this.logging.trace({
          message: 'An error occurred during summarising text.',
          properties: { err },
          severityLevel: SeverityLevel.Warning,
        });
        this._documentSummaryLoading$.next(false);
      },
    });
  }

  generateDraftStream(query: string) {
    const config = this.config.generateDraft;
    const prompt = this.generatePrompt(config, query);
    const request: CompletionRequest = {
      model: config.model,
      prompt,
      temperature: config.temperature,
      maxTokens: config.max_tokens,
    };

    return this.streamCreateCompletionResponse(request);
  }

  constructor(
    private configService: ConfigService,
    private documentService: DocumentService,
    private auth: AuthService,
    private logging: LoggingService,
    @Inject(BASE_PATH) private baseUrl: string,
  ) {
    this._documentSummary$.subscribe((s) => (this._documentSummary = s));
    this.configService.config$.subscribe((c) => (this.config = c));
  }

  private generatePrompt(config: CompletionSettingsDto, prompt: string) {
    if (config.prependString) {
      prompt = `${config.prependString}\n\n${prompt}`;
    }
    if (config.appendString) {
      prompt = `${prompt}\n\n${config.appendString}`;
    }
    this.logging.trace({
      message: 'Generate Prompt',
      properties: { config, prompt },
    });
    return prompt;
  }

  private streamCreateCompletionResponse(
    request: CreateCompletionRequestDto,
    includeSystemPrompt = true,
    includeDocumentSummary = true,
  ): Observable<string> {
    const response$ = new Subject<string>();
    const body = this.getRequestBody(
      request,
      includeSystemPrompt,
      includeDocumentSummary,
    );
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => this.auth.getAccessTokenInteractive(),
      })
      .build();
    connection.on('data', (data) => {
      response$.next(data);
    });
    connection.on('done', () => {
      // update license info
      this._streamingResponseDone$.next();
      response$.complete();
    });
    connection.on('wordsUsed', (wordsUsed: number) => {
      this.logging.trace({
        message: 'Words used',
        properties: {
          wordsUsed,
        },
      });
      gtag('event', 'legacyInput', {
        wordsUsed,
        prompt: request.prompt,
      });
    });

    connection.on('error', (err) => {
      response$.error(this.getApiError(err));
    });
    connection
      .start()
      .then(() => connection.invoke('CreateChatCompletion', body))
      .catch((err) => {
        response$.error(this.getApiError(err));
      });
    return response$.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getApiError(err: any): Error {
    this.logging.trace({
      message: 'An unsuccessful response from the API has occurred.',
      properties: { err },
      severityLevel: SeverityLevel.Warning,
    });

    const httpErrorResponse = err as HttpErrorResponse;
    let message = `${translate(
      'We are sorry, but something went wrong on the AI service',
    )}. ${translate(
      'Please try your request after a brief wait and contact us if the issue persists',
    )}.`;
    const apiErrorMessage: string =
      typeof err === 'string'
        ? err
        : httpErrorResponse.error?.error?.message ||
          (typeof httpErrorResponse.error === 'string'
            ? httpErrorResponse.error
            : typeof httpErrorResponse.message === 'string'
              ? httpErrorResponse.message
              : '');

    if (
      apiErrorMessage.includes('Connection closed with an error') ||
      apiErrorMessage.includes('OPENAI_TOKEN_ERROR')
    ) {
      message = `${translate(
        'Maximum word input length exceeded',
      )}. ${translate('Please select a shorter text')}.`;
    } else if (apiErrorMessage.includes('USAGE_EXCEEDED')) {
      message = `${translate(
        'You have exceeded your free monthly usage limit',
      )}. ${translate(
        `To continue using GenText, please upgrade by clicking the 'Manage Plan' button below`,
      )}.`;
    } else if (apiErrorMessage.includes('OPENAI_ERROR')) {
      message = `${translate(
        'We are sorry, but the AI service is currently facing a server outage',
      )}. ${translate(
        'We are working to fix the problem as soon as possible',
      )}`;
    }

    return new Error(message);
  }

  private getRequestBody(
    request: CreateCompletionRequestDto,
    includeSystemPrompt = true,
    includeDocumentSummary = true,
  ) {
    let systemPrompt = this.config.systemPrompt;
    if (this._documentSummary && includeDocumentSummary) {
      systemPrompt = `${translate(
        'Consider the following document context',
      )}: ${this._documentSummary}`;
    }

    const chatBody: CreateChatCompletionRequestDto = {
      model: request.model,
      messages: [
        {
          content: request.prompt,
          role: 'user',
        },
      ],
      temperature: request.temperature,
      maxTokens: request.maxTokens,
    };
    if (includeSystemPrompt) {
      chatBody.messages?.unshift({
        content: systemPrompt,
        role: 'system',
      });
    }

    return chatBody;
  }
}
