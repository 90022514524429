/* eslint-disable @typescript-eslint/no-var-requires */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CheckoutRequestPlan, LicenseDto, PlanTerm } from '@gentext/api-client';
import { translate, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, startWith } from 'rxjs';
import { BillingDialogService } from '../billing-dialog.service';
import { LicenseService } from '../license.service';

const completedIcon = require('@fluentui/svg-icons/icons/checkmark_circle_48_regular.svg');

interface PlanSelection {
  title: string;
  price: number;
  oldPrice?: number;
  description: string;
  planType: 'free' | 'premium' | 'business';
}
@UntilDestroy()
@Component({
  selector: 'gentext-manage-plan',
  templateUrl: './manage-plan.component.html',
  styleUrls: ['./manage-plan.component.css'],
})
export class ManagePlanComponent implements OnInit, AfterViewInit {
  completedIcon = completedIcon;
  PlanTerm = PlanTerm;

  loading$ = this.billingDialogService.loading$.pipe(startWith(false));
  hasUnexpectedError$ = this.billingDialogService.hasUnexpectedError$;
  hasBadRequestError$ = this.billingDialogService.hasBadRequestError$;
  resolveResponse$ = this.billingDialogService.resolveResponse$;
  selectedPlanTerm = PlanTerm.Monthly;
  selectedPlanId: string | undefined;
  get currentPlanId() {
    return this.currentLicense?.planId;
  }
  get currentPlanTerm() {
    return this.currentLicense?.planTerm;
  }

  get customerId() {
    return this.currentLicense?.customerId;
  }

  get isManagePlanDisabled$() {
    return this.loading$.pipe(
      map((loading) => {
        if (loading) {
          return true;
        }
        if (this.selectedPlanId === this.currentPlanId) {
          if (this.selectedPlanId === 'free') {
            // if currently free and selected free => disabled
            return true;
          }
          // disabled if same term
          return this.currentPlanTerm === this.selectedPlanTerm;
        }
        return false;
      }),
    );
  }

  planSelections: PlanSelection[] = [];
  private currentLicense: LicenseDto | undefined;
  get monthlyAppearance() {
    return this.selectedPlanTerm === PlanTerm.Monthly ? 'accent' : 'outline';
  }
  get yearlyAppearance() {
    return this.selectedPlanTerm === PlanTerm.Yearly ? 'accent' : 'outline';
  }

  get changePlanButtonText() {
    if (this.selectedPlanId === 'free') {
      return translate('Manage');
    } else if (this.selectedPlanId === 'premium') {
      if (this.currentPlanId === 'free') {
        return translate('Upgrade');
      } else if (this.currentPlanId === 'business') {
        return translate('Downgrade');
      } else {
        if (this.selectedPlanTerm !== this.currentPlanTerm) {
          return translate('Change term');
        }
      }
    } else if (this.selectedPlanId === 'business') {
      if (this.currentPlanId === 'free') {
        return translate('Upgrade');
      } else if (this.selectedPlanTerm !== this.currentPlanTerm) {
        return translate('Change term');
      }
      return translate('Upgrade');
    }
    return translate('Upgrade');
  }
  setPlanTerm(planTerm: PlanTerm) {
    this.selectedPlanTerm = planTerm;
    this.updatePlanSelections();
  }
  upgrade() {
    let plan: CheckoutRequestPlan;
    if (
      this.selectedPlanId === 'free' &&
      this.translocoService.getActiveLang() === 'en'
    ) {
      // When selecting free plan, and the language is English
      // start the Churnkey flow

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['churnkey'].init('show', {
        subscriptionId: this.currentLicense?.subscriptionId,
        customerId: this.currentLicense?.customerId,
        authHash: this.currentLicense?.churnkeyHash,
        appId: 'uc4sq28sy',
        mode: process.env['NX_ENVIRONMENT'] === 'prod' ? 'live' : 'test',
        provider: 'stripe',
        record: true,
        onCancel: async () => {
          let license = await this.licenseService.getLicense();
          let i = 0;
          while (license?.planId !== 'free' && i < 5) {
            license = await this.licenseService.getLicense();
            await new Promise((r) => setTimeout(r, 1000));
            i++;
          }
        },
      });

      return;
    }
    if (
      this.currentPlanId !== 'free' ||
      this.currentLicense?.hasPendingInvoice
    ) {
      // if currently on a non-free plan, or when there's a pending invoice
      this.billingDialogService.showManagePlanDialog();
      return;
    }
    if (this.selectedPlanTerm === 'Monthly') {
      plan =
        this.selectedPlanId === 'business'
          ? CheckoutRequestPlan.BusinessMonthly
          : CheckoutRequestPlan.PremiumMonthly;
    } else {
      plan =
        this.selectedPlanId === 'business'
          ? CheckoutRequestPlan.BusinessYearly
          : CheckoutRequestPlan.PremiumYearly;
    }
    this.billingDialogService.showUpgradeDialog(
      plan,
      undefined,
      this.currentLicense?.customerId || undefined,
    );
  }
  constructor(
    private licenseService: LicenseService,
    private translocoService: TranslocoService,
    private billingDialogService: BillingDialogService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    this.billingDialogService.clearResponse();
    this.updatePlanSelections();
    this.updateSelectedPlanTerm();
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.licenseService.license$.pipe(untilDestroyed(this)).subscribe((l) => {
      this.currentLicense = l;
      this.selectedPlanId = l?.planId;
    });
  }

  private updateSelectedPlanTerm() {
    if (this.currentLicense?.planTerm) {
      this.setPlanTerm(this.currentLicense.planTerm);
    }
  }

  private updatePlanSelections() {
    const freePlanDescription =
      this.currentLicense?.planId === 'free' &&
      this.currentLicense?.wordsLeft &&
      this.currentLicense?.wordsLeft > 0 &&
      this.currentLicense?.wordsLeft < 2000
        ? `${this.currentLicense.wordsLeft}/${translate('2000 words left')}`
        : translate('Get 2000 words per month');
    this.planSelections = [
      {
        title: translate('Free Plan'),
        description: freePlanDescription,
        price: 0,
        planType: 'free',
      },

      {
        title: translate('Premium Plan'),
        description: translate('Get 100,000 words per month'),
        price: this.selectedPlanTerm === 'Monthly' ? 19 : 190,
        oldPrice: this.selectedPlanTerm === 'Yearly' ? 19 * 12 : undefined,
        planType: 'premium',
      },
      {
        title: translate('Business Plan'),
        description: translate('Unlimited usage'),
        price: this.selectedPlanTerm === 'Monthly' ? 49 : 490,
        oldPrice: this.selectedPlanTerm === 'Yearly' ? 49 * 12 : undefined,
        planType: 'business',
      },
    ];
  }
}
