<ng-container *transloco="let t"
  ><div class="flex w-full flex-col">
    <h2 class="mb-2 text-lg">{{ t('extractKeywords.title') }}</h2>
    <p *ngFor="let desc of description" class="mb-1">
      {{ desc }}
    </p>

    <p *ngIf="error" class="mb-2 text-red-500">{{ error }}</p>

    <fluent-button appearance="accent" (click)="run()" [disabled]="isLoading">{{
      hasGenerated ? t('regenerateText') : t('generateText')
    }}</fluent-button>

    <p class="mt-2 w-full text-right">Powered by</p>
    <img
      src="../../assets/Semantic-Scholar-logo.png"
      alt="Semantic Scholar logo"
      class="ss-logo"
    />

    <fluent-progress-ring
      *ngIf="isLoading"
      class="mt-4 self-center"
    ></fluent-progress-ring>
  </div>
</ng-container>
