import { InferType, number, object, string } from 'yup';
export const completionSettings = object({
  model: string()
    .required()
    .oneOf([
      'text-davinci-003',
      'text-curie-001',
      'text-babbage-001',
      'text-ada-001',
      'gpt-3.5-turbo',
    ]),
  temperature: number().required(),
  max_tokens: number().required(),
  appendString: string(),
  prependString: string(),
});

export const configSchema = object({
  title: string().required(),
  systemPrompt: string().required(),
  errorMessage: string().required(),
  generateDraft: completionSettings,
  generateSummary: completionSettings,
  rephraseText: completionSettings,
  extractKeywords: completionSettings,
  generateResearchQA: completionSettings,
});

export type ConfigDto = InferType<typeof configSchema>;
export type CompletionSettingsDto = InferType<typeof completionSettings>;
