import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService, LicenseDto } from '@gentext/api-client';
import { AuthService, AuthState } from '@gentext/auth-office';
import { ConfigDto, ConfigService } from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { OpenAIApiService } from '@gentext/openai';
import { TranslocoService } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  catchError,
  combineLatest,
  filter,
  map,
  take,
  throwError,
  timeout,
} from 'rxjs';
import { LicenseService } from './license.service';
import { LanguageOption, getLanguageOptions } from './menu-options';

export const FEATURE_FLAG_CHATBOT = 'gentext-chatbot';
@Component({
  selector: 'gentext-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isChatEnabled: boolean | undefined = undefined;
  isChatAvailable: boolean | undefined = undefined;
  claritySessionId = '';
  selectedOptionPath = '';
  selectedLanguageOption = 'en';

  config: ConfigDto = this.configService.getDefaultConfig();

  get title() {
    return this.config.title;
  }

  get isAuthPage() {
    return window.location.pathname.startsWith('/auth');
  }

  get isChatPage() {
    return window.location.pathname.startsWith('/chat');
  }

  languageOptions: LanguageOption[] = [];

  get firstName() {
    if (this.authState?.name) {
      const nameParts = this.authState?.name.split(' ');
      return nameParts?.length ? nameParts[0] : '';
    }
    return '';
  }

  accessToken$ = this.authService.accessToken$;
  isLoadingAuth$ = this.authService.loading$;
  authError$ = this.authService.error$;

  showOptimizing$ = combineLatest([
    this.openAIApiService.initialSummaryGenerated$,
    this.openAIApiService.documentSummaryLoading$,
  ]).pipe(map(([initialSummary, loading]) => !initialSummary && loading));
  license: LicenseDto | undefined;

  authState: AuthState | undefined;

  private _documentSummary = '';

  selectedLanguageChange() {
    this.translocoService.setActiveLang(this.selectedLanguageOption);
  }

  selectedOptionPathChange() {
    this.logging.trace({
      message: `Selected option path changed: ${this.selectedOptionPath}`,
      severityLevel: SeverityLevel.Information,
    });

    this.router.navigateByUrl(this.selectedOptionPath);
  }

  goHome() {
    this.router.navigateByUrl('/');
  }
  get isManagePlanActive() {
    return this.router.url.startsWith('/manage-plan');
  }
  get showBackButton() {
    return (
      this.router.url.startsWith('/manage-plan') ||
      this.router.url.startsWith('/refer')
    );
  }

  get isWelcomeScreenActive() {
    return this.router.url.startsWith('/home') && !this.authState;
  }

  get showFeatureSelection() {
    return (
      this.authState &&
      !this.router.url.startsWith('/manage-plan') &&
      !this.router.url.startsWith('/refer')
    );
  }

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private licenseService: LicenseService,
    private logging: LoggingService,
    private openAIApiService: OpenAIApiService,
    private renderer: Renderer2,
    private translocoService: TranslocoService,
    private featureFlagService: FeatureFlagService,
  ) {
    translocoService.setFallbackLangForMissingTranslation({
      fallbackLang: 'en',
    });
  }
  ngOnInit(): void {
    if (this.isAuthPage) {
      this.logging.trace({
        message: '[AppComponent] - On Auth page, ignoring ngOnInit',
      });
      return;
    }

    this.checkChatEnabled();
    this.languageOptions = getLanguageOptions();
    this.configService.config$.subscribe((c) => {
      this.config = c;
    });
    this.configService.isRtlLanguage$.subscribe((isRtl) => {
      const dir = isRtl ? 'rtl' : 'ltr';
      this.renderer.setAttribute(document.querySelector('html'), 'dir', dir);
    });

    this.translocoService.langChanges$.subscribe((l) => {
      this.selectedLanguageOption = l;
    });
    this.licenseService.license$.subscribe((l) => {
      this.license = l;
      this.cdr.detectChanges();
    });

    this.authService.authState$.subscribe((authState) => {
      if (!this.license && authState) {
        this.logging.trace({
          message:
            'AppComponent: no license yet and auth state, retrieving license.',
        });
        this.licenseService.getLicense();
      }
      if (!this.authState && authState) {
        this.logging.trace({
          message: 'AppComponent: signed in, so checking chat',
        });
        this.isChatAvailable = undefined;
        this.checkChatEnabled();
      }
      this.authState = authState;
      this.setClarityIdentifiers();
      this.setGtagIdentifiers();
      this.cdr.detectChanges();
      if (!this._documentSummary) {
        this.logging.trace({
          message: 'AppComponent: no summary yet and auth state, summarising.',
        });
        this.summariseDocument();
      }
    });
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe(() => {
        this.setGtagIdentifiers();
        this.setClarityIdentifiers();
      });
    this.selectedOptionPath = window.location.pathname.substring(1);
    this.authService.getJwtFromStorageEmitAuthState();
    this.listenDocumentChanged();
    this.summariseDocumentSchedule();

    this.openAIApiService.streamingResponseDone$.subscribe(() => {
      // refresh the license after the streaming response is done
      this.licenseService.getLicense();
    });
  }

  private checkChatEnabled() {
    this.featureFlagService
      .featureFlagGet(FEATURE_FLAG_CHATBOT)
      .pipe(
        timeout(5000),
        catchError((error) => {
          this.isChatEnabled = false;
          this.isChatAvailable = false;
          this.router.navigateByUrl('/');
          this.cdr.detectChanges();
          return throwError(() => error);
        }),
      )
      .subscribe(async (r) => {
        this.isChatEnabled = r.overriddenIsEnabled ?? r.isEnabled;
        this.isChatAvailable = r.isEnabled;
        await this.callClarity('set', 'isChatEnabled', this.isChatEnabled);
        await this.callClarity('set', 'isChatAvailable', this.isChatAvailable);
        const ignoredRoutes = ['/redirect', '/billingredirect', '/auth'];
        if (
          this.isChatEnabled &&
          this.authState &&
          !ignoredRoutes.some((r) => this.router.url.startsWith(r))
        ) {
          // only redirect when authenticated
          this.router.navigateByUrl('/chat');
        } else if (!this.isChatEnabled && this.router.url.startsWith('/chat')) {
          // only route when in chat, otherwise it will interfere with other routes
          this.router.navigateByUrl('/');
        }
        this.cdr.detectChanges();
      });
  }

  async signIn() {
    this.authService.refreshState(this.selectedLanguageOption);
  }

  async signOut() {
    await this.authService.signOut();
    this.router.navigateByUrl('/');
  }

  summariseDocumentSchedule() {
    this.openAIApiService.documentSummary$.subscribe(
      (s) => (this._documentSummary = s),
    );
    let checkCount = 0;
    window.setInterval(() => {
      checkCount++;
      const minutesWait =
        !this.license || this.license.planId === 'free' ? 30 : 10;
      this.logging.trace({
        message: `Minutes wait: ${minutesWait}`,
      });
      if (!this.documentSummaryRequired) {
        this.logging.trace({
          message: 'Document not dirty, skipping summarise.',
        });
        return;
      }
      if (checkCount > minutesWait || !this._documentSummary) {
        this.summariseDocument();
        checkCount = 0;
        this.documentSummaryRequired = false;
      }
    }, 1000 * 60);
  }

  summariseDocument() {
    if (!this.authState) {
      this.logging.trace({
        message: 'SummariseDocument - not logged in - ignoring.',
      });
      return;
    }
    return Word.run(async (context) => {
      const doc = context.document.body;
      context.load(doc, 'text');
      await context.sync();
      this.openAIApiService.summariseText(doc.text);
    });
  }

  enableChat() {
    this.featureFlagService
      .featureFlagPost(FEATURE_FLAG_CHATBOT, true)
      .pipe(take(1))
      .subscribe();
    this.router.navigateByUrl('/chat');
  }
  share() {
    const url = this.getShareEmailLink();
    window.open(url);
  }

  managePlan() {
    this.router.navigateByUrl('/manage-plan');
  }

  private getShareEmailLink() {
    const email = '';

    const subject = this.translocoService.translate('email.subject');
    const emailBody = this.translocoService.translate('email.body') as string[];

    return `mailto:${email}?subject=${encodeURIComponent(
      subject,
    )}&body=${encodeURIComponent(emailBody.join('\n'))}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private callClarity(func: string, ...params: any[]): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const clarity = (window as any)['clarity'] as any;
    if (clarity) {
      this.logging.trace({
        message: `[AppComponent] - Calling clarity: ${func}, ${params.join(', ')}`,
      });
      return clarity(func, ...params);
    }
    this.logging.trace({
      message: `[AppComponent] - Clarity not available, ignoring call: ${func}`,
    });
    return Promise.resolve();
  }
  private setGtagIdentifiers() {
    const userId = this.authState?.email;
    if (userId) {
      // only configure gtag if user is logged in
      this.logging.trace({
        message: 'Setting gtag user id',
        properties: { userId },
      });
      gtag('config', 'G-XGVND3X8BY', {
        user_id: userId,
      });
    }
  }
  private async setClarityIdentifiers() {
    const userId = this.authState?.email;

    if (userId) {
      if (this.claritySessionId === '') {
        const hashCode = (s: string) =>
          s.split('').reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
          }, 0);
        this.claritySessionId = `${hashCode(userId)}-${Date.now()}`;
      }
      const pageId = this.router.url;

      // API: window.clarity('identify', userId, sessionId, pageId, friendlyName)
      const clarityResponse = await this.callClarity(
        'identify',
        userId,
        this.claritySessionId,
        pageId,
        userId,
      );
      this.logging.trace({
        message: 'Received clarity identify response',
        properties: { clarityResponse },
        severityLevel: SeverityLevel.Verbose,
      });
    }
  }
  private documentSummaryRequired = true;
  private async listenDocumentChanged() {
    await Word.run(async (context) => {
      const paragraphChanged = async () => {
        this.documentSummaryRequired = true;
      };
      context.document.onParagraphAdded.add(paragraphChanged);
      context.document.onParagraphChanged.add(paragraphChanged);
      context.document.onParagraphDeleted.add(paragraphChanged);
      await context.sync();
    });
  }
}
