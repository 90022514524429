<ng-container *transloco="let t">
  <p class="text-red-500" *ngIf="hasUnexpectedError$ | async">
    {{ t('Something went wrong') }}. {{ t('Please contact us at') }}
    <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
  </p>
  <p class="text-red-500" *ngIf="hasBadRequestError$ | async">
    {{ t('The token has expired or is invalid') }}.
    {{ t('Please contact us at') }}
    <a href="mailto:support@gentext.ai">support&#64;gentext.ai</a>.
  </p>

  <div
    class="text-center [&>p]:text-base"
    *ngIf="resolveResponse$ | async as r; else tmplManageSub"
  >
    <span
      class="grayscale-svg flex justify-center"
      [innerHtml]="completedIcon | sanitizeHtml"
    ></span>
    <h1 class="mt-4 text-2xl">{{ t('Thank you for your subscription') }}</h1>
    <p class="my-6">
      {{ t('Please verify the following information is correct') }}.
      {{ t('If not, please contact us at') }}
      <a href="mailto:support@gentext.ai" class="text-blue-700"
        >support&#64;gentext.ai</a
      >.
    </p>

    <div
      class="grid grid-cols-2 gap-2 [&>span]:text-left [&>strong]:text-right"
    >
      <strong>{{ t('Plan') }}:</strong>
      <span>
        {{
          r.planType?.planId === 'business'
            ? t('Business')
            : r.planType?.planId === 'premium'
              ? t('Premium')
              : 'Unknown'
        }}</span
      >

      <strong>{{ t('Term') }}:</strong>
      <span>
        {{ r.planType?.term === PlanTerm.Monthly ? t('Monthly') : t('Annual') }}
      </span>

      <strong>{{ t('Term') }}:</strong>
      <span *ngIf="r.autoRenews; else tmplNoAutoRenew">{{
        t('Auto renews')
      }}</span>
      <ng-template #tmplNoAutoRenew>
        <span>{{ t('Does not auto renew') }}</span>
      </ng-template>

      <strong>{{ t('End date') }}:</strong> <span>{{ r.endDate | date }}</span>

      <strong>{{ t('Email address') }}:</strong>
      <span class="break-words">{{ r.userEmail }}</span>
    </div>
  </div>
  <ng-template #tmplManageSub>
    <div class="flex w-full flex-row justify-between">
      <fluent-button
        class="flex-1"
        data-testid="monthly-plan-button"
        [appearance]="monthlyAppearance"
        (click)="setPlanTerm(PlanTerm.Monthly)"
        [disabled]="loading$ | async"
        >{{ t('Monthly') }}</fluent-button
      >

      <fluent-button
        class="flex-1"
        data-testid="yearly-plan-button"
        [appearance]="yearlyAppearance"
        (click)="setPlanTerm(PlanTerm.Yearly)"
        [disabled]="loading$ | async"
        >{{ t('Yearly') }}</fluent-button
      >
    </div>
    <div class="mt-2 text-center text-sm text-gray-700">
      {{ t('With the annual plan, you get 2 months free!') }}
    </div>

    <!-- two-way binding does not work with ngModelChange for some reason-->
    <fluent-radio-group
      orientation="vertical"
      class="mt-2 block w-full"
      [ngModel]="selectedPlanId"
      ngDefaultControl
    >
      <fluent-radio
        class="plan-radio relative mb-2 flex w-full flex-row rounded border px-2 py-1"
        [class]="{
          'border-gray-300': selectedPlanId !== p.planType,
          'border-blue-300': selectedPlanId === p.planType,
        }"
        *ngFor="let p of planSelections"
        [attr.data-testid]="'plan-select-' + p.planType.toLowerCase()"
        [value]="p.planType"
        [disabled]="loading$ | async"
        (click)="selectedPlanId = p.planType"
      >
        <div class="flex w-full flex-row items-center justify-between">
          <div class="inline-block">
            <p class="text-lg font-bold">{{ t(p.title) }}</p>
            <p class="text-gray-600">{{ t(p.description) }}</p>
          </div>

          <div class="whitespace-nowrap text-xl font-bold text-blue-800">
            <span
              *ngIf="p.oldPrice"
              class="text-base text-gray-400 line-through"
            >
              ${{ p.oldPrice }}</span
            >
            <span> ${{ p.price }}</span>
          </div>
        </div>
      </fluent-radio>
    </fluent-radio-group>

    <fluent-button
      class="w-full"
      appearance="accent"
      (click)="upgrade()"
      [disabled]="isManagePlanDisabled$ | async"
      data-testid="change-plan-button"
      >{{ changePlanButtonText }}</fluent-button
    >
    <fluent-progress-ring
      *ngIf="loading$ | async"
      class="mx-auto block w-4"
    ></fluent-progress-ring>
  </ng-template>
</ng-container>
