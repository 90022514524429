import { Injectable } from '@angular/core';
import {
  PaperSearchRequestDto,
  PaperSearchResponseDto,
  PaperService,
  SemanticScholarPaperDto,
} from '@gentext/api-client';
import { ConfigDto, ConfigService } from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { TranslocoService } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { firstValueFrom } from 'rxjs';

export interface ReferenceResponse {
  abstractsString: string;
  papers: SemanticScholarPaperDto[];
}
@Injectable({
  providedIn: 'root',
})
export class PaperProcessingService {
  config: ConfigDto = this.configService.getDefaultConfig();

  async getPapers(keywords: string): Promise<PaperSearchResponseDto> {
    const searchRequest: PaperSearchRequestDto = {
      limit: 3,
      query: keywords,
    };
    const apiResponse = await firstValueFrom(
      this.paperApiClient.paperSearchPapersPost(searchRequest),
    );

    if (!apiResponse.papers || apiResponse.papers.length === 0) {
      throw new Error(this.translocoService.translate('noPapersFound'));
    }
    return apiResponse;
  }
  async getReferences(
    keywords: string[],
    prompt: string,
  ): Promise<ReferenceResponse> {
    const limitedKeywords = keywords.slice(0, 10).join(', ');
    const searchRequest: PaperSearchRequestDto = {
      limit: 2,
      query: limitedKeywords,
    };
    const apiResponse = await firstValueFrom(
      this.paperApiClient.paperSearchPapersPost(searchRequest),
    );
    this.loggingService.trace({
      message: 'Paper Search Response',
      properties: { apiResponse },
    });
    if (!apiResponse.papers || apiResponse.papers.length === 0) {
      throw new Error(this.translocoService.translate('noPapersFound'));
    }

    const processedTitles = new Set<string>(); // This set keeps track of processed titles

    const uniqueAbstracts: string[] = [];
    const papers: SemanticScholarPaperDto[] = [];
    apiResponse.papers.forEach((paper: SemanticScholarPaperDto) => {
      if (!processedTitles.has(paper.title)) {
        const authorLastName =
          paper.authors.length > 0
            ? (paper.authors[0].name.split(' ').pop() ?? 'unknown author')
            : 'unknown author';

        uniqueAbstracts.push(
          `${uniqueAbstracts.length + 1}. ${paper.title} (${authorLastName}, ${
            paper.year
          })`,
        );

        processedTitles.add(paper.title);
        papers.push(paper);
      }
    });

    const abstractsString: string =
      `Research question: ${prompt} Abstracts: ` + uniqueAbstracts.join(' ');
    this.loggingService.trace({
      message: 'Paper Process Service - Abstract',
      properties: {
        abstractsString,
        papers,
      },
    });
    return {
      abstractsString: abstractsString,
      papers: papers,
    };
  }

  async insertPapersAsFootnote(
    range: Word.Range,
    context: Word.RequestContext,
    papers: SemanticScholarPaperDto[],
  ) {
    this.loggingService.trace({
      message: 'Inserting Papers as footnote',
      properties: { papers },
    });
    for (let i = 0; i < papers.length; i++) {
      const paper = papers[i];

      const footnote = range.insertFootnote('');
      const footnoteBody = footnote.load('body');

      const firstParagraph = footnoteBody.body.paragraphs.getFirst();
      this.loggingService.trace({
        message: `Url number ${i} is ${paper.url}`,
        severityLevel: SeverityLevel.Information,
      });

      const authors = paper.authors
        .map((a) => `${a.lastName}, ${a.initials}`)
        .join(', ');
      const authorsRange = firstParagraph.insertText(
        `${authors} (${paper.year})`,
        'End',
      );
      authorsRange.font.italic = false;

      const titleRange = firstParagraph.insertText(` ${paper.title}`, 'End');
      titleRange.font.italic = false;

      titleRange.hyperlink = paper.url;

      if (paper.journal) {
        const journalText = [paper.journal.name, paper.journal.volume]
          .filter((t) => !!t)
          .join(' ');

        const journalRange = firstParagraph.insertText(
          ` ${journalText}`,
          'End',
        );
        journalRange.font.italic = true;
        if (paper.journal.pages) {
          const pagesRange = firstParagraph.insertText(
            `, ${paper.journal.pages}`,
            'End',
          );
          pagesRange.font.italic = false;
        }
      }

      // After inserting a footnote and if it's not the last paper, insert a comma.
      if (i < papers.length - 1) {
        const commaRange = range.insertText(',', Word.InsertLocation.after);
        commaRange.font.superscript = true;
      }
    }
    await context.sync();
  }
  constructor(
    private paperApiClient: PaperService,
    private configService: ConfigService,
    private loggingService: LoggingService,
    private translocoService: TranslocoService,
  ) {
    this.configService.config$.subscribe((c) => {
      this.config = c;
    });
  }
}
