import { Route } from '@angular/router';
import { authRoutes } from '@gentext/auth-office';
import { CHECKOUT_SESSION, MANAGE_PLAN } from './billing-dialog.service';
import { BillingRedirectComponent } from './billing-redirect/billing-redirect.component';
import { ChatAppComponent } from './chat-app/chat-app.component';
import { ChatManagePlanComponent } from './chat-manage-plan/chat-manage-plan.component';
import { ChatUiComponent } from './chat-ui/chat-ui.component';
import { GenerateDraftTextComponent } from './generate-draft-text/generate-draft-text.component';
import { GeneratePapersComponent } from './generate-papers/generate-papers.component';
import { GenerateResearchQAComponent } from './generate-research-QA/generate-research-QA.component';
import { GenerateSummaryComponent } from './generate-summary/generate-summary.component';
import { HomeComponent } from './home/home.component';
import { ManagePlanComponent } from './manage-plan/manage-plan.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RephraseTextComponent } from './rephrase-text/rephrase-text.component';

export const routes: Route[] = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'chat',
    component: ChatAppComponent,
    children: [
      {
        path: '',
        component: ChatUiComponent,
        data: { reuseRoute: true },
      },
      {
        path: 'manage-plan',
        component: ChatManagePlanComponent,
      },
    ],
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'generate-draft',
    component: GenerateDraftTextComponent,
  },
  {
    path: 'rephrase',
    component: RephraseTextComponent,
  },
  {
    path: 'generate-papers',
    component: GeneratePapersComponent,
  },
  {
    path: 'generate-summary',
    component: GenerateSummaryComponent,
  },
  {
    path: 'generate-research-QA',
    component: GenerateResearchQAComponent,
  },
  {
    path: 'manage-plan',
    component: ManagePlanComponent,
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: `billingredirect/${CHECKOUT_SESSION}`,
    component: BillingRedirectComponent,
  },
  {
    path: `billingredirect/${MANAGE_PLAN}`,
    component: BillingRedirectComponent,
  },
  {
    path: 'auth',
    title: 'Authentication',
    children: authRoutes,
  },
  { path: '**', redirectTo: 'home' },
];
