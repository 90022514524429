<ng-container *ngIf="isAuthPage; else tmplMain">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #tmplMain>
  <div
    *ngIf="isChatEnabled === undefined"
    class="flex size-full min-h-screen flex-col items-center justify-center bg-white"
  >
    <ng-container *transloco="let t">
      <img src="assets/logo.svg" class="mb-4 self-center" />
      {{ t('Initialising') }}...
      <fluent-progress-ring class="mt-4"></fluent-progress-ring>
    </ng-container>
  </div>
  <ng-container *ngIf="!isChatPage; else tmplChat">
    <ng-container *ngTemplateOutlet="tmplRegularApp"></ng-container>
  </ng-container>
</ng-template>
<ng-template #tmplChat>
  <router-outlet></router-outlet>
</ng-template>
<ng-template #tmplRegularApp>
  <ng-container *transloco="let t"
    ><div
      class="relative flex min-h-screen flex-col bg-white p-4"
      id="app-main-card"
    >
      <gentext-alerts-banner></gentext-alerts-banner>
      <div class="flex w-full flex-row justify-between gap-4">
        <fluent-select
          title="Select a language"
          class="w-[100px] min-w-[100px]"
          [(ngModel)]="selectedLanguageOption"
          (ngModelChange)="selectedLanguageChange()"
          name="selectedOption"
          ngDefaultControl
        >
          <fluent-option disabled="disabled" value="">A / 文</fluent-option>
          <fluent-option
            *ngFor="let option of languageOptions; let i = index"
            [value]="option.code"
            >{{ option.display }}</fluent-option
          >
        </fluent-select>

        <fluent-button
          class="w-[100px]"
          *ngIf="authState"
          (click)="signOut()"
          [disabled]="isLoadingAuth$ | async"
          data-testid="signOutButton"
          >{{ t('signOut') }}</fluent-button
        >
      </div>

      <div *ngIf="!isWelcomeScreenActive" class="flex flex-col">
        <!-- don't show on home when not logged in as home html has a heading in that case-->
        <img src="assets/logo.svg" class="mb-4 self-center" />

        <h1 class="mb-4 text-center text-xl font-bold">
          {{ title }}
        </h1>
      </div>
      <div
        class="mb-2 flex w-full flex-row items-end justify-between"
        *ngIf="authState"
      >
        <span> {{ t('greeting') }} {{ firstName }}</span>
        <span class="text-xs font-normal text-gray-400" *ngIf="license">
          <!-- t(license.free, license.premium, license.business) -->
          <span
            class="block ltr:text-right rtl:text-left"
            data-testid="licenseIndicator"
            >{{ t('license.title') }}:
            {{ t('license.' + license.planId) }}</span
          >
          <span class="block ltr:text-right rtl:text-left"
            >{{ t('wordsLeft') }}:
            {{ license.isUnlimited ? '&infin; ' : license.wordsLeft }}
          </span>
          <span
            class="block ltr:text-right rtl:text-left"
            *ngIf="license.expiryDateTime"
            >{{ t('End date') }}:
            {{ license.expiryDateTime | date }}
          </span>
        </span>
      </div>
      <hr *ngIf="!isWelcomeScreenActive" />
      <div *ngIf="showFeatureSelection" class="my-2">
        <gentext-feature-selection
          data-testid="feature-select"
        ></gentext-feature-selection>
      </div>
      <p class="text-base text-red-500" *ngIf="authError$ | async as error">
        {{ t('authError') }} {{ error }}
      </p>

      <div class="mt-4">
        <router-outlet></router-outlet>
      </div>

      <div
        *ngIf="showOptimizing$ | async"
        class="mt-4 flex flex-row items-center justify-center gap-4 text-xs italic text-orange-600"
      >
        <span class="block"> {{ t('optimizing') }}</span>
        <fluent-progress-ring class="block w-4"></fluent-progress-ring>
      </div>

      <div>
        <gentext-business-plan-offer
          *ngIf="!isManagePlanActive"
        ></gentext-business-plan-offer>
      </div>

      <div
        id="footer"
        class="mt-auto flex w-full flex-row justify-between gap-[8px] bg-white pt-5 [&>*]:flex-1"
      >
        <fluent-button
          *ngIf="!showBackButton"
          (click)="managePlan()"
          [disabled]="!license"
          appearance="filled"
          class="text-xs"
          data-testid="manage-plan-button"
          >{{ t('manageSubscription') }}</fluent-button
        >
        <fluent-button
          *ngIf="showBackButton"
          (click)="goHome()"
          appearance="filled"
          class="text-xs"
          >{{ t('back') }}</fluent-button
        >
        <fluent-anchor
          href="mailto:support@gentext.ai"
          appearance="filled"
          class="bg-gray-100 text-xs"
          data-testid="support-link"
          >{{ t('help') }}</fluent-anchor
        >
        <fluent-button
          *ngIf="!isChatAvailable; else tmplChatButton"
          (click)="share()"
          appearance="filled"
          class="text-xs"
          [disabled]="!license"
          >{{ t('share') }}</fluent-button
        >
        <ng-template #tmplChatButton>
          <span class="relative inline-flex">
            <fluent-button
              (click)="enableChat()"
              appearance="filled"
              class="border-1 refer-button w-full whitespace-normal rounded-md bg-blue-500 text-xs ring-slate-900/10"
              data-testid="chat-mode-button"
              >{{ t('Chat mode') }}</fluent-button
            >
            <span class="absolute right-0 top-0 -mr-1 -mt-1 flex size-3">
              <span
                class="absolute inline-flex size-full animate-ping rounded-full bg-blue-400 opacity-75"
              ></span>
              <span
                class="relative inline-flex size-3 rounded-full bg-blue-500"
              ></span>
            </span>
          </span>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
