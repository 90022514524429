import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfigDto, ConfigService } from '@gentext/config';
import { LoggingService } from '@gentext/logging';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DocumentService } from '../document.service';

@UntilDestroy()
@Component({
  selector: 'gentext-rephrase-text',
  templateUrl: './rephrase-text.component.html',
  styleUrls: ['./rephrase-text.component.css'],
})
export class RephraseTextComponent implements OnInit {
  isLoading = false;
  isErrorNoText = false;
  hasGenerated = false;
  error = '';
  ignoreDocSelChange = false;
  config: ConfigDto = this.configService.getDefaultConfig();

  isRtlLanguage$ = this.configService.isRtlLanguage$;

  get description(): string[] {
    return translate('rephraseText.description');
  }
  constructor(
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    private logging: LoggingService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    this.configService.config$.pipe(untilDestroyed(this)).subscribe((c) => {
      this.config = c;
    });
    Office.context.document.addHandlerAsync(
      Office.EventType.DocumentSelectionChanged,
      () => {
        if (this.isLoading) {
          this.logging.trace({
            message: 'Already loading, ignoring doc sel change',
            severityLevel: SeverityLevel.Information,
          });
          return;
        }
        if (this.ignoreDocSelChange) {
          this.ignoreDocSelChange = false;
          return;
        }
        this.hasGenerated = false;
        this.isLoading = false;
        this.cdr.detectChanges();
      },
    );
  }

  async run() {
    this.isLoading = true;
    try {
      await this.documentService.rephrase();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.error = e;
    } finally {
      this.isLoading = false;
      this.hasGenerated = true;
      this.ignoreDocSelChange = true;
    }
  }
}
