<ng-container *transloco="let t"
  ><ng-container *ngIf="(authenticated$ | async) !== true">
    <div class="my-6 flex flex-col">
      <img src="assets/logo.svg" class="mb-4 self-center" />
    </div>
    <p class="mb-5 text-center text-2xl font-bold">{{ t('home.welcome') }}</p>

    <p class="mb-5 text-center text-lg">{{ t('home.description') }}</p>
    <fluent-button
      class="my-1 w-full"
      (click)="signIn()"
      appearance="accent"
      [disabled]="isLoadingAuth$ | async"
      data-testid="signInButton"
      >{{ t('signIn') }}</fluent-button
    >
    <fluent-button class="my-1 w-full" (click)="support()">{{
      t('help')
    }}</fluent-button>
    <p class="my-4 text-center text-sm" data-testid="home-welcome">
      {{
        t(
          'GenText is an AI-powered MS Word Add-in that helps academics generate
      accurate and professional research reports quickly'
        )
      }}.
    </p>
    <p class="my-4 text-center text-sm">
      {{
        t(
          'We use a database of over 200 million papers to help you cite and
      write research accurately'
        )
      }}.
    </p>
  </ng-container>
</ng-container>
