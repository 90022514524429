<ng-container *transloco="let t"
  ><div class="flex w-full flex-col">
    <p
      *ngFor="let desc of description"
      class="mb-1"
      data-testid="generate-draft-desc"
    >
      {{ desc }}
    </p>
    <p *ngIf="isErrorNoText" class="mb-2">
      {{ t('noTextSelected') }}
    </p>
    <p *ngIf="error" class="mb-2 text-red-500">{{ error }}</p>

    <fluent-text-area
      *ngIf="queryText"
      class="mb-4"
      [(ngModel)]="queryText"
      name="queryText"
      ngDefaultControl
      rows="4"
    ></fluent-text-area>
    <fluent-button appearance="accent" (click)="run()" [disabled]="isLoading">{{
      hasGenerated ? t('regenerateText') : t('generateText')
    }}</fluent-button>

    <fluent-progress-ring
      *ngIf="isLoading"
      class="mt-4 self-center"
    ></fluent-progress-ring>
  </div>
</ng-container>
