<div class="px-2">
  <hr class="my-10 mb-2" />
  <fluent-button
    class="my-3 flex w-full flex-row justify-between space-x-2"
    appearance="filled"
    (click)="goBack()"
    >Back</fluent-button
  >
  <gentext-manage-plan></gentext-manage-plan>
</div>
