import { Injectable } from '@angular/core';
import {
  PaperSearchResponseDto,
  SemanticScholarPaperDto,
} from '@gentext/api-client';
import { LoggingService } from '@gentext/logging';
import { OfficeHelperService } from '@gentext/office';
import { OpenAIApiService } from '@gentext/openai';
import { PaperProcessingService } from '@gentext/papers';
import { translate } from '@jsverse/transloco';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { concatMap } from 'rxjs';
const MAX_REFERENCE_COUNT = 3;
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  findCitations() {
    return new Promise<void>((resolve, reject) => {
      return Word.run(async (context) => {
        let text = '';
        const selectedRange = context.document.getSelection();
        context.load(selectedRange, 'text');
        await context.sync();
        text = selectedRange.text;

        if (!text || text === '') {
          reject(translate('noTextSelected'));
          return;
        }

        try {
          const keywords: string[] =
            await this.openAIApiService.extractKeywords(text);
          this.logging.trace({
            message: `Keywords: ${keywords}`,
            severityLevel: SeverityLevel.Information,
          });

          if (!keywords || keywords.length === 0) {
            this.logging.trace({
              message:
                'No keywords returned from the service for Generate Papers',
              severityLevel: SeverityLevel.Warning,
            });
            return;
          }
          await this.insertFootnotesForKeywords(context, keywords);
          resolve();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.logging.trace({
            message: 'Something went wrong inserting references',
            properties: { e },
            severityLevel: SeverityLevel.Warning,
          });

          reject(this.officeHelper.getErrorMessage(e));
        }
      });
    });
  }
  generateResearchAnswer() {
    return new Promise<void>((resolve, reject) => {
      Word.run(async (context) => {
        let text = '';
        const selectedRange = context.document.getSelection();
        context.load(selectedRange, 'text');
        await context.sync();
        text = selectedRange.text;

        if (!text || text === '') {
          reject(translate('noTextSelected'));
          return;
        }
        const generatedParagraphs: Word.Paragraph[] = [];
        try {
          const keywords = await this.openAIApiService.extractKeywords(text);
          const answer = await this.paperProcessingService.getReferences(
            keywords,
            text,
          );
          const doc = context.document;
          const selection = doc.getSelection();
          let paragraph = selection.insertParagraph('', 'After');
          paragraph.styleBuiltIn = Word.BuiltInStyleName.normal;
          context.trackedObjects.add(paragraph);
          context.trackedObjects.add(selection);
          await context.sync();

          generatedParagraphs.push(paragraph);
          let pendingFootnote = false; // to track if we've encountered a '^'
          let footnoteCount = 0; // to track how many footnotes have been added
          let prevCharWasFootnote = false; // to track if the previous character was a footnote
          this.logging.trace({
            message: 'Find Research - Processing answer',
            properties: answer,
          });
          this.openAIApiService
            .generateAnswer(answer.abstractsString)
            .pipe(
              concatMap(async (data) => {
                if (data.endsWith('^')) {
                  pendingFootnote = true; // we've found a '^', so we'll set this flag to true
                  data = data.replace('^', ''); // do not append this to the buffer or process, instead wait for the next chunk
                }
                if (data.trim() === '') {
                  return;
                }

                // if the current chunk starts with a number and we had a '^' in the previous chunk
                if (pendingFootnote && /^\d+/.test(data)) {
                  if (footnoteCount < MAX_REFERENCE_COUNT) {
                    if (answer.papers.length < footnoteCount + 1) {
                      this.logging.trace({
                        message:
                          'Unexpected response from OpenAI, reference returned but we did not have enough papers',
                        severityLevel: SeverityLevel.Warning,
                        properties: {
                          answer,
                          footnoteCount,
                        },
                      });
                    } else {
                      await this.insertPapersAsFootnote(
                        paragraph,
                        context,
                        answer.papers[footnoteCount],
                        prevCharWasFootnote,
                      );
                      prevCharWasFootnote = true;

                      await context.sync();
                      footnoteCount++; // increment the counter
                    }
                  }

                  data = data.replace(/^\d+/, ''); // remove the leading numbers
                  pendingFootnote = false; // reset the flag
                } else {
                  prevCharWasFootnote = false;
                }

                let textRange: Word.Range;
                if (data.endsWith('\n')) {
                  const text = data.replace('\n', '');
                  textRange = paragraph.insertText(text, 'End');
                  paragraph = paragraph.insertParagraph('', 'After');
                  paragraph.styleBuiltIn = Word.BuiltInStyleName.normal;
                  context.trackedObjects.add(paragraph);
                  generatedParagraphs.push(paragraph);
                } else {
                  textRange = paragraph.insertText(data, 'End');
                }
                // setting superscript to false, as it is true after inserting a foot note ref.
                textRange.font.superscript = false;

                await context.sync();
              }),
            )

            .subscribe({
              error: (err) => {
                this.logging.trace({
                  message: 'Research QA component subscription error',
                  properties: { err },
                  severityLevel: SeverityLevel.Error,
                });
                reject(this.officeHelper.getErrorMessage(err));
              },
              complete: async () => {
                this.logging.trace({
                  message: 'Research QA component subscription done',
                  severityLevel: SeverityLevel.Information,
                });
                while (
                  footnoteCount < MAX_REFERENCE_COUNT &&
                  answer.papers.length - 1 >= footnoteCount
                ) {
                  await this.insertPapersAsFootnote(
                    paragraph,
                    context,
                    answer.papers[footnoteCount],
                    prevCharWasFootnote,
                  );
                  prevCharWasFootnote = true;

                  await context.sync();
                  footnoteCount++;
                }
                selection.select();
                await context.sync();
                resolve();
              },
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.logging.trace({
            message: 'DocumentService generateResearchAnswer exception',
            properties: { e },
            severityLevel: SeverityLevel.Error,
          });
          reject(this.officeHelper.getErrorMessage(e));
        }
      });
    });
  }
  rephrase(): Promise<void> {
    return new Promise((resolve, reject) => {
      return Word.run(async (context) => {
        let text = '';

        const selectedRange = context.document.getSelection();
        context.trackedObjects.add(selectedRange);
        context.load(selectedRange, 'text');
        await context.sync();
        text = selectedRange.text;

        if (!text || text === '') {
          reject(translate('noTextSelected'));
          return;
        }

        const prompt = `${text}`;
        try {
          const generatedParagraphs: Word.Paragraph[] = [];

          let paragraph = selectedRange.insertParagraph('', 'After');
          generatedParagraphs.push(paragraph);
          context.trackedObjects.add(paragraph);
          await context.sync();
          let allText = '';
          this.openAIApiService
            .rephraseText(prompt)
            .pipe(
              concatMap(async (data) => {
                allText += data;
                data = data.replace('**', '').replace(/#/g, '');

                if (data.endsWith('\n')) {
                  const text = data.replace('\n', '');
                  paragraph.insertText(text, 'End');
                  paragraph = paragraph.insertParagraph('', 'After');
                  paragraph.styleBuiltIn = Word.BuiltInStyleName.normal;
                  context.trackedObjects.add(paragraph);
                  generatedParagraphs.push(paragraph);
                } else {
                  paragraph.insertText(data, 'End');
                }
                await context.sync();
              }),
            )
            .subscribe({
              error: (err) => {
                this.logging.trace({
                  message: 'Text component rephrasing subscription error',
                  properties: { err },
                  severityLevel: SeverityLevel.Error,
                });
                reject(this.officeHelper.getErrorMessage(err));
              },
              complete: async () => {
                this.logging.trace({
                  message: 'Text component rephrasing done',
                  severityLevel: SeverityLevel.Information,
                });

                await this.clearParagraphs(generatedParagraphs);
                await this.officeHelper.insertMarkdown(allText);

                selectedRange.clear();
                await context.sync();
                resolve();
              },
            });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.logging.trace({
            message: 'Text component rephrasing exception',
            properties: { e },
            severityLevel: SeverityLevel.Error,
          });
          reject(this.officeHelper.getErrorMessage(e));
        }
      });
    });
  }
  constructor(
    private openAIApiService: OpenAIApiService,

    private officeHelper: OfficeHelperService,
    private logging: LoggingService,
    private paperProcessingService: PaperProcessingService,
  ) {}

  private async clearParagraphs(paragraphs: Word.Paragraph[]) {
    for (let i = 0; i < paragraphs.length; i++) {
      const paragraph = paragraphs[i];
      paragraph.delete();
      await paragraph.context.sync();
      paragraph.context.trackedObjects.remove(paragraph);
    }
  }

  private async clearRange(range: Word.Range) {
    range.delete();
    await range.context.sync();
    range.context.trackedObjects.remove(range);
  }

  private async insertFootnotesForKeywords(
    context: Word.RequestContext,
    keywords: string[],
  ) {
    const keywordsSubset = keywords.slice(0, 10);
    let papersData: PaperSearchResponseDto | undefined = undefined;

    papersData = await this.paperProcessingService.getPapers(
      keywordsSubset.join('+'),
    );
    if (!papersData?.papers) {
      this.logging.trace({
        message: 'No papers found',
        severityLevel: SeverityLevel.Error,
      });

      return;
    }
    // we're getting Content such that it excludes hidden characters like new lines
    // was fixed as part of https://github.com/GenText/gentext/issues/763
    const selection = context.document.getSelection().getRange('Content');

    await this.paperProcessingService.insertPapersAsFootnote(
      selection,
      context,
      papersData.papers,
    );
  }
  private async insertPapersAsFootnote(
    paragraph: Word.Paragraph,
    context: Word.RequestContext,
    paper: SemanticScholarPaperDto,
    prevCharWasFootnote: boolean,
  ) {
    const lastRange = paragraph.getRange('End');
    context.trackedObjects.add(lastRange);
    await context.sync();
    if (prevCharWasFootnote) {
      // insert comma
      const commaRange = lastRange.insertText(',', 'End');
      commaRange.font.superscript = true;
      await context.sync();
    }
    this.logging.trace({
      message: `Insert paper as footnote`,
      properties: { paper, prevCharWasFootnote },
      severityLevel: SeverityLevel.Information,
    });
    this.paperProcessingService.insertPapersAsFootnote(lastRange, context, [
      paper,
    ]);
  }
}
