import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlagService } from '@gentext/api-client';
import { AuthService, AuthState } from '@gentext/auth-office';
import { LoggingService } from '@gentext/logging';
import { TranslocoService } from '@jsverse/transloco';
import { combineLatest, map, take } from 'rxjs';
import { FEATURE_FLAG_CHATBOT } from '../app.component';
import { ChatUiService } from '../chat-ui.service';
import { LicenseService } from '../license.service';
import { LanguageOption, getLanguageOptions } from '../menu-options';

@Component({
  selector: 'gentext-chat-app',
  templateUrl: './chat-app.component.html',
  styleUrl: './chat-app.component.css',
})
export class ChatAppComponent implements OnInit, OnDestroy {
  languageOptions: LanguageOption[] = [];
  claritySessionId = '';
  selectedLanguageOption = 'en';
  selectedOptionPath = '';
  isAuthenticated = false;
  authState: AuthState | undefined;
  authState$ = this.authService.authState$;
  get isManagePlanVisible() {
    return this.router.url === '/chat/manage-plan';
  }
  firstName$ = this.authState$.pipe(
    map((a) => {
      const nameParts = a?.name?.split(' ');
      return nameParts?.length ? nameParts[0] : '';
    }),
  );
  greeting$ = combineLatest([
    this.firstName$,
    this.translocoService.selectTranslate('Welcome'),
  ]).pipe(map(([firstName, welcome]) => `${welcome} ${firstName}`));
  isLoadingAuth$ = this.authService.loading$;
  license$ = this.licenseService.license$;
  menuVisible = false;

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
    this.cdr.detectChanges();
  }

  selectedLanguageChange() {
    this.translocoService.setActiveLang(this.selectedLanguageOption);
  }

  goBack() {
    this.router.navigateByUrl('/chat');
  }
  goToOldUI() {
    this.featureFlagService
      .featureFlagPost(FEATURE_FLAG_CHATBOT, false)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigateByUrl('/');
      });
  }

  async signIn() {
    this.authService.refreshState();
  }

  async signOut() {
    this.chatUiService.clearMessages();
    await this.authService.signOut();
    this.router.navigateByUrl('/');
  }

  managePlan() {
    this.router.navigateByUrl('/chat/manage-plan');
  }

  support() {
    const link = `mailto:support@gentext.ai`;
    window.open(link);
  }

  constructor(
    private authService: AuthService,
    private logging: LoggingService,
    private licenseService: LicenseService,
    private router: Router,
    private chatUiService: ChatUiService,
    private cdr: ChangeDetectorRef,
    private translocoService: TranslocoService,
    private featureFlagService: FeatureFlagService,
  ) {
    // only hidden in chat app, therefore can't do in styles.css
    document.body.style.overflow = 'hidden';
  }

  ngOnDestroy() {
    document.body.style.overflow = 'visible';
  }

  ngOnInit() {
    this.languageOptions = getLanguageOptions();
    this.authService.authState$.subscribe((a) => {
      if (!a) {
        return;
      }
      this.licenseService.getLicense();
    });
    this.authService.authState$.subscribe((authState) => {
      this.isAuthenticated = !!authState;
      this.cdr.detectChanges(); // Manually trigger change detection
    });
    this.selectedOptionPath = window.location.pathname.substring(1);
    if (!this.selectedOptionPath.startsWith('auth')) {
      this.logging.trace({
        message: 'AppComponent: refreshing state silently.',
      });
      this.authService.getJwtFromStorageEmitAuthState();
    }
    this.authService.authState$.subscribe((authState) => {
      this.authState = authState;
      this.cdr.detectChanges();
    });
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Check if the click is outside the menu
    const isClickInsideMenu = (event.target as HTMLElement).closest(
      '.menu-class-name',
    );

    if (!isClickInsideMenu && this.menuVisible) {
      this.toggleMenu();
      this.cdr.detectChanges(); // Update the view
    }
  }
}
