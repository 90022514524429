import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ApiModule, BASE_PATH } from '@gentext/api-client';
import { AuthConfiguration, AuthOfficeModule } from '@gentext/auth-office';
import { CHAT_SERVICE, ChatComponent, LICENSE_SERVICE } from '@gentext/chat-ui';
import { ConfigModule, ConfigService } from '@gentext/config';
import { LoggingModule } from '@gentext/logging';
import {
  GET_DEFAULT_ERROR_MESSAGE,
  ReuseRoutesRouterStrategy,
} from '@gentext/office';

import { AlertsBannerComponent } from './alerts-banner/alerts-banner.component';
import { AppComponent } from './app.component';
import { BillingRedirectComponent } from './billing-redirect/billing-redirect.component';
import { BusinessPlanOfferComponent } from './business-plan-offer/business-plan-offer.component';
import { ChatAppComponent } from './chat-app/chat-app.component';
import { ChatManagePlanComponent } from './chat-manage-plan/chat-manage-plan.component';
import { ChatUiService } from './chat-ui.service';
import { ChatUiComponent } from './chat-ui/chat-ui.component';
import { FeatureSelectionComponent } from './feature-selection/feature-selection.component';
import { GenerateDraftTextComponent } from './generate-draft-text/generate-draft-text.component';
import { GeneratePapersComponent } from './generate-papers/generate-papers.component';
import { GenerateResearchQAComponent } from './generate-research-QA/generate-research-QA.component';
import { GenerateSummaryComponent } from './generate-summary/generate-summary.component';
import { HomeComponent } from './home/home.component';
import { LicenseService } from './license.service';
import { ManagePlanComponent } from './manage-plan/manage-plan.component';
import { RedirectComponent } from './redirect/redirect.component';
import { RephraseTextComponent } from './rephrase-text/rephrase-text.component';
import { routes } from './routes';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { TranslocoRootModule } from './transloco-root.module';
const apiHost = process.env['NX_API_HOST'];

if (!apiHost) {
  throw new Error('NX_API_HOST environment variable is required');
}
const apiBaseUrl = `https://${apiHost}`;
const authConfiguration: AuthConfiguration = {
  clientId:
    process.env['NX_AUTH_CLIENT_ID'] || '6c6e3b09-64d6-40c2-97ba-7fff9305f2b9',
  authority: `https://${process.env['NX_AUTH_TENANT_NAME']}.b2clogin.com/${process.env['NX_AUTH_TENANT_NAME']}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
  apiBaseUrl,
  tenantName: process.env['NX_AUTH_TENANT_NAME'] || 'GentextTestEnv',
};
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    GenerateDraftTextComponent,
    HomeComponent,
    GeneratePapersComponent,
    GenerateSummaryComponent,
    GenerateResearchQAComponent,
    RephraseTextComponent,
    FeatureSelectionComponent,
    SanitizeHtmlPipe,
    ManagePlanComponent,
    RedirectComponent,
    BillingRedirectComponent,
    BusinessPlanOfferComponent,
    ChatAppComponent,
    ChatUiComponent,
    ChatManagePlanComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    ClipboardModule,
    BrowserModule,
    FormsModule,
    ConfigModule,
    AuthOfficeModule.forRoot(authConfiguration),
    LoggingModule.forRoot({
      consoleEnabled: process.env['NX_CONSOLE_ENABLED'] === 'true',
    }),
    RouterModule.forRoot(routes, { useHash: false }),
    ApiModule,
    TranslocoRootModule,
    ChatComponent,
    AlertsBannerComponent,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ReuseRoutesRouterStrategy,
    },
    {
      provide: CHAT_SERVICE,
      useClass: ChatUiService,
    },
    {
      provide: LICENSE_SERVICE,
      useClass: LicenseService,
    },
    {
      provide: BASE_PATH,
      useValue: apiBaseUrl,
    },
    {
      provide: GET_DEFAULT_ERROR_MESSAGE,
      deps: [ConfigService],
      useFactory: (configService: ConfigService) => {
        return () => {
          return (
            configService.currentConfig.errorMessage || 'An error occurred'
          );
        };
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
