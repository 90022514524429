import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, map } from 'rxjs';
import * as ARABIC_CONFIG from './arabic.config.json';
import { ConfigDto } from './config.dto';
import * as DEFAULT_CONFIG from './default.config.json';
import * as FRENCH_CONFIG from './french.config.json';
import * as GERMAN_CONFIG from './german.config.json';
import * as HEBREW_CONFIG from './hebrew.config.json';
import * as JAPANESE_CONFIG from './japanese.config.json';
import * as KOREAN_CONFIG from './korean.config.json';
import * as MANDARIN_CONFIG from './mandarin.config.json';
import * as RUSSIAN_CONFIG from './russian.config.json';
import * as SPANISH_CONFIG from './spanish.config.json';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config$ = new BehaviorSubject<ConfigDto>(this.getConfig('en'));
  config$ = this._config$.asObservable();
  get currentConfig() {
    return this._config$.value;
  }

  isRtlLanguage$ = this.translocoService.langChanges$.pipe(
    map((l) => l === 'he' || l === 'ar'),
  );

  getDefaultConfig() {
    return DEFAULT_CONFIG;
  }

  private getConfig(language: string) {
    switch (language) {
      case 'fr':
        return FRENCH_CONFIG;
      case 'es':
        return SPANISH_CONFIG;
      case 'ru':
        return RUSSIAN_CONFIG;
      case 'zh':
        return MANDARIN_CONFIG;
      case 'ko':
        return KOREAN_CONFIG;
      case 'he':
        return HEBREW_CONFIG;
      case 'de':
        return GERMAN_CONFIG;
      case 'ja':
        return JAPANESE_CONFIG;
      case 'ar':
        return ARABIC_CONFIG;
      default:
        return DEFAULT_CONFIG;
    }
  }

  constructor(private translocoService: TranslocoService) {
    translocoService.langChanges$.subscribe((l) => {
      this._config$.next(this.getConfig(l));
    });
  }
}
