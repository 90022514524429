export interface MenuOption {
  path: string;
  titleKey: string;
}

export interface LanguageOption {
  name: string;
  code: string;
  display?: string;
}

export function getLanguageOptions(): LanguageOption[] {
  return [
    { name: 'english', display: 'English', code: 'en' },
    { name: 'arabic', display: 'عربى', code: 'ar' },
    { name: 'mandarin', display: '中文', code: 'zh' },
    { name: 'hebrew', display: 'עברית', code: 'he' },
    { name: 'french', display: 'Français', code: 'fr' },
    { name: 'german', display: 'Deutsch', code: 'de' },
    { name: 'japanese', display: '日本语', code: 'ja' },
    { name: 'korean', display: '한국어', code: 'ko' },
    { name: 'spanish', display: 'Español', code: 'es' },
    { name: 'russian', display: 'Русский', code: 'ru' },
    { name: 'portuguese', display: 'Português', code: 'pt' },
  ];
}

export function getMenuOptions(): MenuOption[] {
  /**
   * t(generateDraft.selectionPrompt)
   * t(generateSummary.selectionPrompt)
   * t(rephraseText.selectionPrompt)
   * t(extractKeywords.selectionPrompt)
   * t(generateResearchQA.selectionPrompt)
   */
  const menuOptions = [
    {
      path: 'generate-draft',
      titleKey: 'generateDraft.selectionPrompt',
    },
    {
      path: 'generate-summary',
      titleKey: 'generateSummary.selectionPrompt',
    },
    {
      path: 'rephrase',
      titleKey: 'rephraseText.selectionPrompt',
    },
    {
      path: 'generate-papers',
      titleKey: 'extractKeywords.selectionPrompt',
    },
    {
      path: 'generate-research-QA',
      titleKey: 'generateResearchQA.selectionPrompt',
    },
  ];

  return menuOptions.filter((option) => !!option.titleKey);
}
